import { css } from '@emotion/react'
import { isLink, isList } from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import { rgba } from 'polished'
import { ComponentPropsWithoutRef } from 'react'
import { renderNodeRule } from 'react-datocms/structured-text'

import {
  DatoButton,
  DatoLink,
  DatoStructuredTextLink,
  DatoStructuredTextLinkData,
} from '@/features/dato-link'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { DatoStructuredText } from '../DatoStructuredText'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | Queries.BodyTextBlockFragment
    | Queries.BodyTextBlockShortFragment
    | null
  disableLightboxLinks?: boolean
}

export const BodyTextBlock = ({
  data,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const styles = {
    block: css`
      max-width: 92ch;
      > p:first-of-type {
        margin-top: 0.25em;
      }
      a {
        color: var(--link-color, ${colors.red});
        @media (hover: hover) {
          &:hover {
            color: var(--link-color-hover, ${colors.redDark});
          }
        }
      }
      b,
      strong {
        font-weight: 625;
      }
      hr {
        border: none;
        height: 0.5rem;
        background: rgba(0, 0, 0, 0.05);
        margin: 2em 0;
      }
      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
      blockquote {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 1.25em 0 1.5em;
        padding: 0.5em 2em 1em;
        background-color: rgba(0, 0, 0, 0.05);
        font-size: var(--fs-21);
        font-style: italic;
        color: ${rgba(colors.gray20, 0.8)};
        &:before {
          content: '“';
          display: block;
          position: absolute;
          font-family: var(--ff-display);
          font-style: normal;
          font-size: 300%;
          top: 0em;
          left: 0.2em;
          opacity: 0.333;
        }
        p {
          margin: 0.5em 0;
          &:last-of-type:after {
            content: '”';
            display: inline;
            font-family: var(--ff-display);
            font-style: normal;
            font-size: 150%;
            line-height: 0;
            opacity: 0.5;
          }
        }
        footer {
          font-size: var(--fs-15);
          font-weight: 500;
          color: ${rgba(colors.gray20, 0.67)};
        }
      }
    `,
    list: css`
      padding-inline-start: 1.5em;
      margin-block: 0.5em 1.25em;
      box-sizing: border-box;
      column-fill: balance;
      li {
        padding-inline-start: 0.25em;
        break-inside: avoid;
        ::marker {
          color: ${colors.red};
          font-weight: 500;
        }
        p {
          line-height: 1.5;
          margin: 0 0 0.75em;
        }
      }
      > li {
        list-style-type: disc;
        > ul > li {
          list-style-type: circle;
          > ul > li {
            list-style-type: square;
          }
        }
      }
    `,
    twoColumnList: css`
      column-count: 2;
      gap: 3em;
      ${mq().s} {
        column-count: 1;
      }
    `,
    threeColumnList: css`
      column-count: 3;
      gap: 3em;
      ${mq().m} {
        column-count: 2;
      }
      ${mq().s} {
        column-count: 1;
      }
    `,
    button: css`
      p + & {
        margin-top: 2em;
      }
    `,
  }
  return (
    <div
      css={styles.block}
      {...props}
    >
      <DatoStructuredText
        data={data?.body}
        renderLinkToRecord={({ record, children, transformedMeta }) => (
          <DatoStructuredTextLink
            data={record}
            disableLightboxLinks={disableLightboxLinks}
            {...transformedMeta}
          >
            {children}
          </DatoStructuredTextLink>
        )}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case 'DatoCmsDatoButton':
              return (
                <DatoButton
                  css={styles.button}
                  data={record}
                  disableLightboxLinks={disableLightboxLinks}
                />
              )
            default:
              return (
                <DatoLink
                  data={record}
                  showIcon
                  disableLightboxLinks={disableLightboxLinks}
                />
              )
          }
        }}
        renderInlineRecord={({ record }) => (
          <DatoStructuredTextLink
            data={record as DatoStructuredTextLinkData}
            disableLightboxLinks={disableLightboxLinks}
            inline
          />
        )}
        customNodeRules={[
          renderNodeRule(isLink, ({ node, children, key }) => (
            <a
              key={key}
              href={node.url}
              target="_blank"
              rel="noreferrer"
              {...node.meta}
            >
              {children}
            </a>
          )),
          renderNodeRule(isList, ({ node, children, key }) => {
            const listItemsLengthsArray = children?.flatMap(
              (child_i: any) =>
                child_i.props.children.flatMap((child_ii: any) =>
                  child_ii.props.children.flatMap((child_iii: any) =>
                    child_iii.props.children.map?.(
                      (child_iv: string) => child_iv.length
                    )
                  )
                )
            ) as number[] | undefined
            const List = node.style === 'bulleted' ? 'ul' : 'ol'
            const getColumnCss = () => {
              const longestItem =
                listItemsLengthsArray &&
                Math.max(...listItemsLengthsArray)
              if (longestItem && listItemsLengthsArray.length > 1) {
                if (longestItem < 144 && longestItem > 40) {
                  return styles.twoColumnList
                }
                if (longestItem <= 40) {
                  return styles.threeColumnList
                }
              }
            }
            return (
              <List
                css={[styles.list, getColumnCss()]}
                key={key}
              >
                {children}
              </List>
            )
          }),
        ]}
      />
    </div>
  )
}

export const BodyTextBlockFragment = graphql`
  fragment BodyTextBlock on DatoCmsBodyTextBlock {
    __typename
    id: originalId
    __typename
    body {
      value
      links {
        ... on DatoCmsMissionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsHomePage {
          __typename
          id: originalId
          slug
          pageName
        }
        ... on DatoCmsDivisionPage {
          __typename
          id: originalId
          slug
          divisionName
        }
        ... on DatoCmsTourPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStoriesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsNewsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsTertiaryArticle {
          __typename
          id: originalId
          slug
          heading
        }
        ... on DatoCmsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsInteriorPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticFacilitiesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsTeamsSchedulesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsNewsletterPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsAwardsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsOurPeoplePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsDirectoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsGivingPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsBlackbaudForm {
          __typename
          id: originalId
          slug
          heading
        }
        ... on DatoCmsDivisionAdmissionsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsEventsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsFaqPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsTuitionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsContactAndDirectionsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeAssociationPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeAwardsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeEventsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeArticlesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeDirectoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStrategicVisionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsHistoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStatsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAcademicCalendarPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsLandingPage {
          __typename
          id: originalId
          slug
          pageTitle
        }
      }
      blocks {
        ... on DatoCmsPageLink {
          ...PageLink
        }
        ... on DatoCmsExternalLink {
          ...ExternalLink
        }
        ... on DatoCmsTertiaryArticleLink {
          ...TertiaryArticleLink
        }
        ... on DatoCmsPdfLink {
          ...PdfLink
        }
        ... on DatoCmsFormLink {
          ...FormLink
        }
        ... on DatoCmsDatoButton {
          ...DatoButton
        }
      }
    }
  }
  fragment BodyTextBlockShort on DatoCmsBodyTextBlockShort {
    __typename
    id: originalId
    __typename
    body {
      value
      links {
        ... on DatoCmsMissionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsHomePage {
          __typename
          id: originalId
          slug
          pageName
        }
        ... on DatoCmsDivisionPage {
          __typename
          id: originalId
          slug
          divisionName
        }
        ... on DatoCmsTourPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStoriesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsNewsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsTertiaryArticle {
          __typename
          id: originalId
          slug
          heading
        }
        ... on DatoCmsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsInteriorPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticFacilitiesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsTeamsSchedulesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsNewsletterPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAthleticsAwardsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsOurPeoplePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsDirectoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsGivingPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsBlackbaudForm {
          __typename
          id: originalId
          slug
          heading
        }
        ... on DatoCmsDivisionAdmissionsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsWelcomePage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsEventsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAdmissionsFaqPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsTuitionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsContactAndDirectionsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeAssociationPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeAwardsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeEventsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeArticlesPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAlumnaeDirectoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStrategicVisionPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsHistoryPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsStatsPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsAcademicCalendarPage {
          __typename
          id: originalId
          slug
          pageHeading
        }
        ... on DatoCmsLandingPage {
          __typename
          id: originalId
          slug
          pageTitle
        }
      }
    }
  }
`
